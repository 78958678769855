<template>
    <div class="logout-page">
      <div class="container text-center">
        <h1 class="text-white">Logout in corso...</h1>
        <p class="text-white">Attendi un momento...</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    async mounted() {
      // Rimuovi i dati dell'utente
      localStorage.removeItem('userEmail');
      localStorage.removeItem('userToken');
      localStorage.removeItem('tokenExpiration');
      
      // Reindirizza alla pagina di login
      this.$router.push({ name: 'login' });
    },
  };
  </script>
  
  <style scoped>
  .logout-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #292D7D; /* Blu coerente con l'app */
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
  </style>
  