<template>
    <div class="success-page">
        <div class="content">
            <h1>
                <i class="fa-regular fa-circle-check text-white"></i>
            </h1>
            <h3 class="fw-bold">Registrazione completata!</h3>
            <p>Ti è stata inviata una e-mail per verificare il tuo account.</p>
            <router-link to="/login">
                <button class="btn btn-custom">Torna all'App</button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "SuccessPage",
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.success-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Full page height */
    background-color: #292D7D;
    /* Background blu */
}

.content {
    text-align: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: 3rem;
    font-weight: 600;
}

p {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 40px;
}

.success-image {
    width: 150px;
    height: 150px;
    margin-bottom: 30px;
}

/* Stile del pulsante */
.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    font-size: 1.2rem;
}

.btn-custom:hover {
    background-color: #cc2e31;
    color: white;
}
</style>