<template>
  <div id="app">
    <router-view /> 
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.title = 'Elite Fitness'
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Elite Fitness'
    }
  }
};
</script>

<style>
body{
  background-color: #292D7D;
}
</style>
