<template>
    <div class="login-page">
        <div class="container">
            <a href="https://elitefitness.it">
                <img src="@/assets/logo.png" alt="Logo" class="logo">
            </a>
            <p>Accedi inserendo le tue credenziali.</p>
            <form class="m-2" @submit.prevent="login">
                <div class="form-group">
                    <input v-model="email" type="email" placeholder="Email" class="form-control" required />
                </div>
                <div class="form-group">
                    <input v-model="password" type="password" placeholder="Password" class="form-control" required />
                </div>
                <router-link to="/password-reset">
                    <p class="text-white">
                        Ho dimenticato la mia password!
                    </p>
                </router-link>
                <button 
                    type="submit" 
                    class="btn btn-custom mt-4"
                    :disabled="isLoading"
                    :class="{ 'loading': isLoading }"
                >
                    <span v-if="!isLoading">Login</span>
                    <span v-else>
                        <span class="loading-dots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </span>
                    </span>
                </button>
            </form>
            <router-link to="/register">
                <p class="text-white">
                    Non hai un account? <i>Registrati ora</i>
                </p>
            </router-link>
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            errorMessage: '',
            isLoading: false, // Stato di caricamento
        };
    },
    methods: {
        async login() {
            this.isLoading = true; // Attiva il caricamento
            this.errorMessage = ''; // Resetta eventuali messaggi di errore
            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/auth/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.email,
                        password: this.password,
                    }),
                });

                if (!response.ok) {
                    this.errorMessage = 'Credenziali non valide. Riprova.';
                    this.isLoading = false; // Disattiva il caricamento
                    return;
                }

                const data = await response.json();

                localStorage.setItem('userEmail', this.email);
                localStorage.setItem('userToken', data.token);
                localStorage.setItem('tokenExpiration', data.expiresIn);

                const action = this.$route.query.action; // Legge il parametro action dalla query string
                const productId = this.$route.query.productId; // Legge productId, se presente
                const variationName = this.$route.query.variationName; // Legge variationName, se presente
                const discountCode = this.$route.query.discountCode; // Legge discountCode, se presente
                if (action) {
                    this.$router.push({ path: `/${action}`, query: { productId, variationName, discountCode } });
                } else {
                    this.$router.push({ name: 'account', query: { productId, variationName, discountCode } });
                }
            } catch (error) {
                console.error('Login failed:', error);
                this.errorMessage = 'Si è verificato un errore durante il login. Riprova più tardi.';
            } finally {
                this.isLoading = false; // Disattiva il caricamento
            }
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

@media (min-width: 1400px) {
    .login-page .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px;
        width: 500px;
    }
}

.login-page {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #292D7D;
    color: white;
}

.logo {
    max-width: 200px;  /* Adjust this value as needed */
    margin-bottom: 20px;
}

.text-danger {
    color: red;
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 0;
    font-size: 1.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-custom.loading {
    background-color: #cc2e31; /* Colore più chiaro durante il caricamento */
    cursor: not-allowed;
}

.btn-custom:disabled {
    cursor: not-allowed;
    background-color: #cc2e31;
}

.loading-dots {
    display: inline-flex;
    gap: 2px;
}

.loading-dots span {
    animation: blink 1.4s infinite;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0%, 100% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
}

.form-control {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0.085);
    border: 2px solid;
    border-color: white;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}
</style>
